import { LitElement, html } from 'lit';

import install from '@twind/with-web-components';
import config from '../../twind.config';

const withTwind = install(config);

class ModalComponent extends withTwind(LitElement) {

  static properties = {
    showModal: { type: Boolean },
    transparent: { type: Boolean }, // 添加一个控制背景透明度的属性
  };

  get customizeStyle() {
    return html``;
  }

  constructor() {
    super();
    this.transparent = false; // 默认为不透明
  }

  render() {
    return html`
            <style>
            ${this.customizeStyle}
            </style>
            <div class="${this.showModal ? 'block' : 'hidden'}  fixed z-[9000] inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="flex relative ${this.transparent ? 'items-center' : 'items-end'} justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
                    <div class="fixed inset-0 bg-[#000] bg-opacity-50 transition-opacity" aria-hidden="true"></div>
                    <div class="${this.transparent ? 'bg-transparent flex w-full h-full justify-center items-center' : 'bg-white'} rounded-[20px] absolute ${this.transparent ? 'items-center' : 'top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'}">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
            `;
  }

}

customElements.define('wb-modal', ModalComponent);
